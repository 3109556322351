.theme-btn,
.theme-btn-s4{
	background: $primary-color;
	color: $white;
	display: inline-block;
	padding: 18px 45px;
	border: 2px solid transparent;
	border-radius: 5px;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 19px;
	font-weight: 700;
	line-height: 25px;
	transition: all .4s ease-in-out;


	&:after {
		position: unset;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 40px;
		background: darken($primary-color, 8%);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
	}

	&:hover,
	&:focus,
	&:active {
		background: $white;
		border: 2px solid $primary-color ;
		color: $text-color;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}

#appointment-right .theme-btn {
	@extend .theme-btn;
	background-color: $accent-color ;
	transition: background-color 0.3s ease;
}

.theme-btn-s2 {
	background: $white;
	color: $text-color;
	display: inline-block;
	padding: 12px 22px;
	border: 1px solid $primary-color;
	border-radius: 55px;
	text-transform: capitalize;

	z-index: 1;
	position: relative;

	@include media-query(991px) {
		font-size: 14px;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($primary-color, 5%);
		// @include linear-gradient-bg(left, #00ded8, #00a7d5);
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}
}



.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;
	

	&:after{
		display: none;
	}
	&:hover{
		background-color: darken($primary-color, 8%);
	}

	&.s1{
		background: #ddd;

		&:hover{
			background-color: darken(#ddd, 8%);
		}
	}

}

.theme-btn-accent-big {
	@extend .theme-btn;
	display: inline-block;
	background-color: $accent-color ;
	transition: background-color 0.3s ease;
	color: $neutral-primary-color;
	&:focus{
		background-color: $accent-color !important; /* Original color */
		border: none;
	}
	&:focus:hover{
		background-color: $neutral-primary-color !important; /* Original color */
	}
}

.theme-btn-accent {
	@extend .theme-btn;
	display: inline-block;
	margin-top: 15px;
	padding: 10px 45px;
	background-color: $accent-color ;
	transition: background-color 0.3s ease;
	color: $neutral-primary-color;
	
	&:focus{
		background-color: $accent-color !important; /* Original color */
		border: none;
		color:$neutral-primary-color;
	}
	&:focus:hover{
		background-color: $white !important; /* Original color */
		color:$neutral-primary-color;
	}
}

.theme-btn-accent-2 {
	@extend .theme-btn;
	display: inline-block;
	margin-top: 15px;
	padding: 10px 45px;
	background-color: $accent-color-2 ;
	color:$neutral-primary-color;
}
