// @import '../../sass/colors.scss';
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.p-20{
    padding:20px 0;
}
.section-title {
    margin-bottom: 50px;
    // text-align: center;
    
    span {
        color: $primary-color;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 20px;
        display: inline-block;
    }

    h2 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        color: $text-color;
        font-weight: 500;
    }
}

// Mobile Responsive
@media (max-width: 575px){
    .section-common {
        margin: 0 15px;
        border-radius: 20px;
    }

    p {
        font-size: 16px;
    }

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .section-common {
        margin: 0 15px;
        border-radius: 20px;
    }

    p {
        font-size: 16px;
    }

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
}

// Medium Responsive
@media (min-width: 758px) and (max-width: 991px) {
    .pt-100 {
        padding-top: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
}
// Mobile Responsive
@media (max-width: 575px){
    .section-title {
        margin-bottom: 20px;

        span {
            margin-bottom: 5px;
        }

        h2 {
            font-size: 30px;
        }        
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .section-title {
        margin-bottom: 20px;
        span {
            margin-bottom: 5px;
        }
        h2 {
            font-size: 30px;
        }        
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-title {
        margin-bottom: 30px;
        span {
            margin-bottom: 5px;
        }
        h2 {
            font-size: 30px;
        }        
    }
}

.features-section {
    .section-title {
        h2 {
            max-width: inherit;
        }

        p {
            max-width: 800px;
        }
    }
}

// ICON
.icon-list {
    display: flex;
    margin-bottom: 50px;

    .icon-list-text {
        margin-left: 30px;
        max-width: 300px;

        h3 {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 500;
        }
    }
}

// Mobile Responsive
@media (max-width:575px){
    .icon-area {
        .icon-list {
            margin-bottom: 25px;
        }
    }
}
.service-box {
    border: 1px solid #1819451a;
    padding: 15px;
    border-radius: 32px;
    text-align: center;
    margin-bottom: 30px;

    .icon-area {
        background: #DBDEFA;
        padding: 80px;
        border-radius: 30px;
        margin-bottom: 30px;

        .icon-box {
            margin: auto;
        }
    }

    .service-text {
        h3 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;

            a {
                text-decoration: none;
                color: #181945;
            }
        }

        p {
            font-size: 14px;
        }
    }
}
// Small Device Responsive
@media (min-width: 576px) and (max-width: 767px) {
    .service-box {
        .icon-area {
            padding: 50px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .service-box {
        .icon-area {
            padding: 50px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 992px) and (max-width: 1199px) {
    .service-box {
        .icon-area {
            padding: 50px;
        }
    }
}


.icon-box {
    background: $white;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    text-align: center;

    &::before {
        position: absolute;
        content: '';
        width: 100px;
        height: 100px;
        background: $white;
        top: -10px;
        left: -10px;
        z-index: -1;
        opacity: .3;
        border-radius: 20px;
    }

    img {
        max-width: 50px;
        // filter: hue-rotate(180deg); 
    }
}


// Mobile Responsive
@media (max-width: 575px) {
    .icon-box {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;

        &::before {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        img {
            max-width: 25px;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .icon-box {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;

        &::before {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        img {
            max-width: 25px;
        }
    }
}
.section-bg {
    background-color: $neutral-color-2 !important;
}
.section-bg-2 {
    background-color: #ebebeb !important; //$neutral-color-4;
//     --bottom: radial-gradient(calc(10px + 50px) at 50% -10px,#0000 98%,#000);
//   -webkit-mask: var(--bottom);
//           mask: var(--bottom);
//   margin-top: -40px;

}

.section-divider-style1 {
    //top
    // clip-path: polygon(0 0,50% 56px,100% 0,100% 100%,0 100%);
    clip-path: polygon(0 0,50% 80px,100% 0,100% 100%,0 100%);
    margin-top: -46px;

    // //bottom
    // clip-path: polygon(0 0,100% 0,100% calc(100% - 56px),50% 100%,0 calc(100% - 56px));

}
.section-divider-style2 {
    //3D style
    --s: 70px;  /* control the size */
    --a: 90deg; /* control the angle  */
    --d: 20px;  /* the depth */

    //   height: 280px;
    width: 100%;
    background: 
        conic-gradient(from calc(180deg - var(--a)/2) at 50% var(--d),
        #0004,#0000 1deg calc(var(--a) - 1deg),#0008 var(--a) calc(180deg + var(--a)/2),#0004 0) 
        50%/var(--s) #E1F5F1; /* the main color */
    mask: 
        conic-gradient(from calc(180deg - var(--a)/2) at top,
        #0000,#000 1deg calc(var(--a) - 1deg),#0000 var(--a)) 50%/var(--s);
}

.corner {
    --s: 50px; /* size of the corners */
    border: 8px solid $secondary-color-2; 
    padding: 10px;
    // height: 250px;
    // width: 300px;
    // background: #f2f2f2 content-box;
    mask: 
        conic-gradient(#000 0 0) content-box,
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0) 
        0 0/calc(100% - var(--s)) calc(100% - var(--s));
}
// .section-common {
//     border-radius: 48px;
//     margin: 0 25px;
// }

// for feature style 2
.feature-wrapper {
    position: relative;
    text-align: center;
    padding: 10px 35px;
    // border: 2px solid $border-color;
    border-radius: 8px;
    
    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .icon-container {
            width: 120px;
            height: 120px; 
            border: 2px solid $neutral-primary-color;
            border-radius: 50%; /* Makes it a circle */
            background-color: $neutral-color-2; /* Circle background color */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        svg{
            width: 60%; /* Adjust icon size */
            height: auto; /* Maintain aspect ratio */
            color: $neutral-primary-color;
        }
        p{
            text-align: left;
        }
    }

    span {
        font-family: $heading-font;
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;
        color: $text-color;
        display: block;
        margin-top: 40px;
        margin-bottom: 5px;

    }
}