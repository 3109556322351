#faq-extra{
    text-align: center;
    
    margin-top:3rem;
    p, a {
        color: $text-color;
        font-size: 17px;
    }
}


#faq-read-more{
    margin-top: 1rem;
    a{
        color: $text-color;
        font-size: 15px;
        &:hover{
            color: $black;
            text-decoration: underline;
        }
    }
}

#more-reviews-btn {
  margin-top: 30px;
}
