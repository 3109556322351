// @import '../../sass/colors.scss';
.testimonail-section {
    background-color: $secondary-color;
    .row {
        align-items: center;
    }
    .section-title h2 {
        color: $white;
    }
    .pt-5{
        line-height: 1.8em;
        color: $white;
        font-size: 18px;
    }
    .sliderWrapper {
        padding: 3rem 0;
    }
    
}
.single-testimoni {
    width: 100%;
    padding: 50px 10px 0;
    margin-bottom: 30px;
}

.testimonial-card {
    text-align: center;
    background: $neutral-color-2;
    border-radius: 32px;
    padding: 70px 30px 30px;
    position: relative;

    .testimonial-img {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        margin: auto;

        img {
            border: 8px solid $accent-color-2;
            border-radius: 50%;
            display: inline-block;
        }
    }

    .testimonial-text {
        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 15px;
        }
        p {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    // .testimonial-rating {
    //     padding: 0;
    //     margin: 0;

    //     li {
    //         display: inline-block;
    //         color: #FF8C4B;
    //         font-size: 25px;
    //         margin: 0 2px;

    //         &:last-child {
    //             color: #C4C4C4;
    //         }
    //     }
    // }
    .testimonial-rating {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            
            font-size: 25px;
            margin: 0 2px;
        }

        li.good-review{
            color: #FF8C4B;
        }

        li.bad-review {
            color: #C4C4C4;
        }
    }

    .testimoni-slider {
        .slick-arrow {
            display: none !important;
        }
    }
    
}

.slick-prev:before, .slick-next:before {
    color:$text-color;
}

.all-testimonials-section {
    .testimoni-slider {
        .slick-arrow {
            display: none !important;
        }
    }
}

.ratings-wrap-box {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: $white;
    padding: 30px;
    justify-content: space-between;
    border-bottom: 3px solid #002345;
}

.ratings-wrap-box .content .list {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    
    li.good-review{
        color: #FF8C4B;
    }
    li span {
        margin-left: 10px;
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .ratings-wrap-box {
        display: block;
        padding: 25px;
    }
}
@media only screen and (max-width: 767px) {
    .ratings-wrap-box .content .list {
        margin-bottom: 15px;
    }
}