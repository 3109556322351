// #location_hero {
//     align-items: center;
//     .col-lg-6 .col-md-12 .col-12{
//         align-self: center;
//     }
// }

.office-info {
    // max-width: 400px;
    // margin: 20px auto;  // Adds some margin to center the container on the page
    // padding: 20px;
    .office-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
        color: $heading-color;
      }
    
    .office-address {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: $text-color;
    }

    .office-hours {
        display: flex;
        flex-direction: column;
        // max-width: 300px;
        max-width: 100%;
        margin: 0 auto;
      
        .office-hour {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          border-bottom: 1px solid #ccc;
      
          &:last-child {
            border-bottom: none;
          }
      
          .day {
            flex: 1;
            text-align: left;
            font-weight: bold;
          }
      
          .time {
            flex: 1;
            text-align: right;
            color: #555;
          }
        }
    }   
}

.office-buttons {
    display: flex;
    justify-content: space-around;
    margin: 1.5rem 0;

    @media (max-width:768px) {
        flex-direction: column;
        align-items: center; 
        a{
            margin: 0.5rem 0;
        }
    }
  }

#about-dr-hero-img img {
    border-radius: 50%;
}

#about-dr-hero-section {
    #dr-mission {
        // color: '#fff',
        // line-height: 25px;
        max-width: 680px;
        // marginBottom: '40px',
        color: $text-color;
        line-height: 1.8em;
        font-size: 18px;
    }
}

a.learn-more {
    color: $text-color;
    text-decoration: none;
    display: flex;
    align-items: center;
   
    &:hover {
        color: $black;
        text-decoration: underline;
    }

    svg {
      margin-left: 5px;
    }
  }



.onemember-wrapper {
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $accent-color-2 100%);
   
    // flex-basis: 28.33%;
    border-radius: 8px;
    padding: 2px;
    transition: all .4s ease-in-out;
    margin: 0 10px;
    @media (max-width:991px) {
        margin-bottom: 30px;
    }

    .onemember {
        padding: 20px 20px;
        // border: 2px solid $secondary-color;
        background: $neutral-color-1;
        text-align: center;
        border-radius: 8px;
        transition: all .4s ease-in-out;

        .img-holder {
            overflow: hidden;
            border-radius: 8px;
            object-fit: cover;

            img {
                transition: all .4s ease-in-out;
                width: 100%;
            }
        }

        .member-name {
            margin-top: 30px;


            h2 {
                a {
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 33px;
                    color: $text-color;
                    margin-bottom: 12px;
                    transition: all .4s ease-in-out;

                    @media (max-width:575px) {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    &:hover{
                        color: $primary-color;
                    }
                }
            }

            span {
                font-size: 20px;
                font-weight: 700;
                line-height: 26px;
                color: $text-light-color;

                @media (max-width:575px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    &:hover {

        .onemember {
            .img-holder {
                img {
                    transform: scale(1.2);
                    border-radius: 8px;
                }
            }
        }

    }
}