

.insurance-section {
    background-color: $body-bg-color;
    .container {
        border-radius: 0 70px 0 70px; //60px 0 60px 0;
        border: 2px solid $secondary-color-2 !important;
    }
    .col {
        flex: 0 0 auto;
        width: 20%;
        padding: {
            right: 0;
            left: 0;
        };
    }

}
.insurance-item {
    text-align: center;
    // border: 1px solid rgba(160, 177, 193, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 145px;
    padding: 25px;
}
/* Max width 767px */
@media only screen and (max-width : 767px) {
    .insurance-section {
      
        padding-left: 1rem;
        padding-right: 1rem;
       
        .col {
            flex: 0 0 auto;
            width: 50%;
            padding: {
                left: calc(var(--bs-gutter-x) * 0.5);
                right: calc(var(--bs-gutter-x) * 0.5);
            };
        }
    }
    .insurance-item {
        // border: 1px solid rgba(160, 177, 193, 0.2) !important;
        height: 100px;
        margin: {
            top: 10.5px;
            bottom: 10.5px;
        };
    }
}

@media only screen and (min-width : 768px) and (max-width : 991px) {
    .insurance-section {
        .col {
            flex: 0 0 auto;
            width: 33.33333333333333%;
            padding: {
                right: calc(var(--bs-gutter-x) * 0.5);
                left: calc(var(--bs-gutter-x) * 0.5);
            };
        }
    }
    .insurance-item {
        // border: 1px solid rgba(160, 177, 193, 0.2) !important;
        height: 100px;
        margin: {
            top: 10.5px;
            bottom: 10.5px;
        };
    }
}