/*=====================================================
6. wpo-service-single
======================================================*/
.wpo-service-single-area{
    .wpo-service-single-wrap{
        .wpo-service-single-title{
            h3{
                font-weight: 500;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px){
                    font-size: 25px;
                }
            }
        }
        .wpo-service-single-item{
            margin-bottom: 40px;
            .wpo-service-single-main-img{
                margin-bottom: 30px;
            }

        }
        .list-widget{
            max-width: 590px;

            ul{
                list-style: none;

                li{
                    padding: 10px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before{
                       position: absolute;
                       left: 0;
                       top: 50%;
                       transform: translateY(-50%);
                       width: 25px;
                       height: 25px;
                       line-height: 25px;
                       border-radius: 50%;
                       background: transparentize($primary-color, .9);
                       content: "\e64c";
                       font-family: "themify";
                       text-align: center;
                       color: $primary-color;
                       font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img{
            margin-bottom: 10px;
        }

        .wpo-service-area{
            padding-bottom: 0;

            .wpo-service-item {
                padding: 20px;

                @media(max-width:1200px){
                    padding: 20px 10px;
                  }

                h2{
                    font-weight: 600;
                    font-size: 22px;
                    margin-bottom: 12px;
                    margin-top: 0;

                    @media(max-width:1400px){
                      font-size: 19px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:1200px){
                      font-size: 17px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:991px){
                      font-size: 22px;
                    }
                }
                p{
                    margin-bottom: 5px;
                }
            }
        }

        .wpo-service-contact-area{
            padding: 30px;
            box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, 0.1);
            border-radius: 20px;
            background: $white;

            .wpo-contact-title{
                margin-bottom: 30px;
            }

            .wpo-contact-form-area{
                .col{
                   margin-bottom: 30px;
                }
                .form-control{
                    height: 50px;
                    border:0 ;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
                textarea.form-control{
                    height: 120px;
                }
                select.form-control{
                    background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }

                .submit-area{
                    button{
                        border-radius: 6px;
                    }
                }
            }
        }
    }


    .Specialist{
        .team-single-text{
            .theme-btn{
                padding: 10px 25px;
                font-size: 15px;
            }
        }
    }

    .wpo-team-section-s2.dentist{
        .team-wrap{
            .team-single{
                @media(max-width:1200px){
                    margin-bottom: 30px;
                }
                .team-boder-shapes-1{
                    padding: 10px;
                    .team-single-text{
                        h2 {
                            @media(max-width:1400px){
                                margin-bottom: 0;
                            }
                            a {
                                @media(max-width:1400px){
                                    font-size: 20px;
                                }
                            }
                        }
                    } 
                }

                .theme-btn{
                    @media(max-width:1400px){
                        padding: 10px 10px;
                        font-size: 14px;
                    }
                }
            }
        } 
    }
}


.icon-container {
    position: relative;
    text-align: center;
    padding: 20px 0;
    // border: 2px solid $border-color;
    // border-radius: 8px;
    // transition: all .4s ease-in-out;
    // margin-bottom: 60px;

    .icon-img-container {
        // background: $white;
        border: 2px solid $secondary-color-2;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: 0 auto; /* Centers the circle horizontally (optional) */
        overflow: hidden; /* Ensures the image stays within the circular boundary */
        padding: 30px 0;
        display: flex;
        justify-content: center; /* centers items horizontally */
        align-items: center; /* centers items vertically */
        .icon-img {
            max-width: 70%; /* Ensures the image takes up the entire circular area */
            // max-height: 80%;
            height: auto; /* Maintains aspect ratio */
            display: block; /* Removes any inline spacing */
        }
    }

    span {
        font-family: $heading-font;
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;
        color: $text-color;
        display: block;
        margin-top: 40px;
        margin-bottom: 5px;

    }
}



  
  

.services-wrapper {
    .circle-click {
        cursor: pointer;
        border-radius: 50%;
        background-color: $secondary-color;
        height: 50px;
        width: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: $primary-color;
            .plus-sign {color: $white;}
        }
        .plus-sign {
            font-size: 24px;
            font-weight: 500;
            transition: transform 0.3s ease;
            color: $white;
          }
        .rotated {
            transform: rotate(45deg);
            color: $white;
            transition: transform 0.3s ease;
          }
      }

    .service-btn-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        button {
            text-align: center;
        }
      }

    // .service-description {
    //     opacity: 0;
    //     height: 0;
    //     overflow: hidden;
    //     transition: opacity 0.3s ease, height 0.3s ease;
    // }
    // .service-description.active {
    //     opacity: 1;
    //     height: auto;
    // }
}
 
 
  

  
//   .additional-content {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 10px;
//   }
  
//   .additional-content p {
//     margin: 0;
//   }
  