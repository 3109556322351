.form {
    input,
    textarea,
    select {
        border-color:( $black, 75% );
        border-radius: 0;
        outline: 0;
        box-shadow: none;
        color: lighten($black, 35%);
        font-style: normal;

        &:focus {
            border-color: $primary-color;
            @include round-box-shadow( lighten($primary-color, 10%), 5px, 0 );
        }
    }

    @include placeholder-style(lighten($black, 35%));

    select {
        font-style: normal;
        // background: url(../../images/select-icon.png) no-repeat right center;
        display: inline-block;
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        cursor:pointer;
    }
    
    select::-ms-expand { /* for IE 11 */
        display: none;
    }


    ::-webkit-input-placeholder { /* Edge */
        font-style: normal;
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-style: normal;
      }
      
      ::placeholder {
        font-style: normal;
      }

     

}

.errorMsg {
  color:$red-color;
}

.consent_checkbox {
  display: flex;
  flex-direction: column;
  // gap: 8px;
  padding: 0 30px;
  @media (max-width: 575px){
    padding-bottom: 10px;
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between the checkbox and the text */
    
    @media (max-width: 575px){
      flex-direction: column; /* Stack checkbox above the text on mobile */
      align-items: flex-start;
    }
  
      .consent-text{
          font-size: 0.8rem;
          line-height: 1.2;
      }
      a{
        color:  $secondary-color-2;
      }
      a:focus, a:hover{
        color: $red-color;
        text-decoration: underline;
      }
  
      input {
        align-self: center;
      }

      input[type=checkbox] {
          align-self: center;
          transform: scale(0.5);
          width:25%;
      }
  }
}

