// .single-img {
//     width: 300px; /* Set a width for each image, adjust as needed */
//     height: 300px;
//     margin: 10px 0; /* Add some space between images */

//     img {
//         width: 100%; /* Ensure the image fills its container */
//         height: 100%; /* Maintain aspect ratio */
//         object-fit: cover; 
//     }
//     img {
//         transition: transform 0.3s ease;
//       }
//     img.zoomed {
//         transform: scale(2); /* Adjust the scale factor for desired zoom level */
//       }
// }

// .single-text p {
//     text-align: left;
//     line-height: 1.1;
//     font-size: 1em;
// }

// #case-gallery-header {
//     text-align:center;
//     padding:1rem
// }

// .swiper-image {
//     max-width: 100%;
//     max-height: 100%;
//     object-fit: cover; /* Maintain aspect ratio and cover the container */
//   }
// .swiper-wrapper {
//     align-items:flex-end;
// }

.case-wrapper {
  margin-bottom: 3rem;
  p {
    font-size: 1.1rem;
  }
  .case-header{
    margin-bottom: 2rem;
  }
}