/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer-s2 {
    background: $secondary-color;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;
    padding-top: 4rem;
    // margin-top: -193px;
    
    @media (max-width:991px) {
        padding-top: 0;
        margin-top: 0;
    }

    z-index: 1;



    &::before {
        position: absolute;
        left: 0;
        bottom: -70px;
        z-index: -1;


        @media(max-width:767px) {
            display: none;
        }

    }

    &::after {
        position: absolute;
        right: 0;
        bottom: -7px;
        z-index: -1;

        @media(max-width:767px) {
            display: none;
        }
    }

    ul {
        list-style: none;
    }

    p {
        color: $neutral-color-1;
    }

    li {
        color: $neutral-color-1;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        // padding: 80px 0;
        padding: 40px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        margin-bottom: 20px;
        h3 {
            font-size: 24px;
            color: $neutral-color-1;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 400;
        }
       
    }

    .about-widget { 
        .logo {
            max-width: 40%;
            // max-width: 150%;
        }
        // h6 {
        //     color: $neutral-color-1;
        // }
    }

    .about-widget {
        p, h6{
            margin-bottom: 0.8em;
            line-height: 1.9em;
            color: $neutral-color-1;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .link-widget,
        .social-widget {

            ul {
                li {
                    display: inline-block;
                    padding-top: 0;
                    padding-right: 15px;

                    a {
                        border: 1px solid #d1d1d1;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            border: 1px solid $secondary-color-2;
                            background-color: $secondary-color-2;
                        }

                    }
                }
            }
        }
    }

    .wpo-contact-widget {
        .contact-ft {
            margin-top: 20px;

            @media(min-width:991px) {
                padding-right: 30px;
            }

            ul {
                li {
                    padding-bottom: 15px;
                    position: relative;
                    padding-left: 35px;
                    color: $neutral-color-1;
                    font-size: 15px;

                    i {
                        position: absolute;
                        left: 0;
                        top: -3px;
                    }

                    .fi:before {
                        font-size: 20px;
                        margin-right: 15px;
                    }
                }
            }
        }

    }

    // .social-widget {
    //     margin-top: 20px;
    // }

    .link-widget,
    .social-widget {
        overflow: hidden;

        @media (max-width: 1199px) {}

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: $neutral-color-1;
                    font-size: 15px;

                    &:hover {
                        color: $neutral-color-1;
                        font-weight: 600;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .link-widget {
            padding-left: 75px;
        }
    }

    .tag-widget {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                a {
                    display: block;
                    padding: 8px 20px;
                    background: rgba(255, 255, 255, .1);
                    font-size: 15px;
                    color: $white;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                }
            }
        }
    }

    .social-widgets {
        ul {
            li {
                a {
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 10px;

                    &:hover {
                        color: $neutral-color-1;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    
    .hour-widget {
        // padding: 0 30px;
        // padding-right: 2rem;
        li{
            color:  $neutral-color-1;
            font-size: 15px;
        }
        .schedule-item {
            display: flex;
            justify-content: space-between; /* Distribute items evenly */
            align-items: center; /* Center items vertically */
            // margin-bottom: 10px; /* Adjust spacing between items */
          }
          
          .day {
            font-weight: bold;
            flex: 1;
          }
          
//           .hour {
//             margin-left: 0px; /* Add space between day and hour */
//             min-width: 80px; /* Adjust minimum width for hour to prevent wrapping */
//   text-align: right; /* Align hour to the right */
//           }
       
    }
    .newsletter-widget {                                                    
        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: $white;
                height: 50px;
                color: $neutral-color-1;
                padding: 6px 20px;
                border-radius: 5px;
                border: none;
                box-shadow: none;
            }

            .submit {
                position: absolute;
                right: 20px;
                top: 55%;
                @include translatingY();

                button {
                    background: transparent;
                    border: 0;
                    outline: 0;
                    font-size: 20px;
                    color: $primary-color;
                    margin-top: -2px;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }


    .wpo-lower-footer {
        text-align: center;
        position: relative;

        .row {
            padding: 20px 0;
            position: relative;
            border-top: 1px solid $secondary-color-2;
        }

        .copyright {
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            font-weight: 500;
            line-height: 22px;

            span {
                color: $neutral-color-1;
                padding: 0 10px 0 5px;
            }

            .copyright-icon {
                padding-right: 10px;
                color: $neutral-color-1;
            }

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }

        .link {
            margin-bottom: 0;
            display: flex;
            justify-content: right;

            a {
                color: $white;
            }

            span {
                color: $primary-color;
                padding: 0 10px;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.wpo-site-footer-s2 {
    padding-top: 0;
    margin-top: 0;
    margin-top: 0;
}

.email-container {
    word-wrap: break-word; /* Break words to prevent overflow */
    overflow-wrap: break-word; /* Another option for word wrapping */
    white-space: normal; /* Allow wrapping of long words and text */
}

.phone-container, .email-container, .address-container {
    &:hover {
        text-decoration: underline;
        // color: $secondary-color;
    }
}






/* Wavy background for footer */
.footer-bg-wave{
    background-color: $secondary-color;
    position: relative;
    margin-top: -10%; 
    overflow: hidden;
    z-index: 100;
    height: 100px;
    clip-path: polygon(0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%);
  }