.wpo-location-section {
    .left-side {
        padding-right: 5rem;
        @media(max-width:767px){
            padding-right: 0rem;
        }
    }
    .office-location-container, .office-hours-container, .office-phone-container, .office-email-container {
        display: flex;
        align-content: center;
        align-self: start;
        margin-bottom: 1rem;
        div {
            margin-right: 2rem;
            svg {
                color: $secondary-color;
                font-size: 35px;
            }
        }

        .info-container p {
            &:hover {
                text-decoration: underline;
                color: $secondary-color;
            }
        }

    }

    .office-hours-container {
        .office-hours {
            padding-right: 5rem;
            @media(max-width:767px){
                padding-right: 0rem;
            }
            .office-hour{
                display:flex;
                justify-content: space-between;
                padding: 8px 0;
                border-bottom: 1px solid $primary-color;
               
            }
        }
       
    }

    // .office-image-container {
    //     padding-top: 5rem;
    // }
    #map-container{
        margin-top: 5rem;
        margin-bottom: 5rem;
        @media(max-width:767px){
            margin-top: 1rem;
        }
        iframe {  
            width:100%;
            height:350px;
            border:0;
        }
    }
 
}

// .office-image-container  img{
//        display: block;
//        width: 100%;
//        border-bottom-left-radius: 10;
//        border-bottom-right-radius: 10;
//        border-top-left-radius: 10;
//        border-top-right-radius: 10;                                           
// }