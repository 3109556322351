// page-title
@mixin page-title-styles {
	min-height: 600px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
    
	@media(max-width: 991px) {
		min-height: 300px;
	}

	@media(max-width: 767px) {
		min-height: 250px;
	}

	@media(max-width: 500px) {
		min-height: 200px;
	}

	@media(max-width: 400px) {
		min-height: 180px;
		background: $white;
	}

	.wpo-breadcumb-wrap {
		text-align: center;
        .welcome-btns {
            display: flex;
            justify-content: center;
			margin-top: 2rem;
            a{
                margin: 0 4rem ;
            }
         
        
            @media (max-width:768px) {
                flex-direction: column;
                align-items: center; 
                a{
                    margin: 0.5rem 0;
                }
            }
          }
		h2 {
			font-size: 60px;
			color: $text-color;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			font-weight: 400;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}
		h1 {
			font-size: 60px;
			color: $text-color;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			font-weight: 400;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ol {
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: inline-block;
				padding: 0px 5px;
				padding-right: 20px;
				color: $text-color-s2;
				position: relative;
				font-size: 20px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 8px;
					height: 8px;
					background: $text-color-s2;
					border-radius: 50%;
				}

				&:last-child {
					padding-right: 0;

					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: $text-color-s2;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $primary-color;
					}
				}
			}
		}
	}
}
// .wpo-welcome{
// 	@include page-title-styles;
// 	background: url(../../images/welcome.jpg) no-repeat center top / cover;
	
// }

.wpo-welcome{
	@include page-title-styles;
	// @extend .wpo-section-title;
	.background-img-container {
		opacity: 0.6;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1000;
	}

	// ::before {
	// 	background: url(../../images/welcome.jpg) no-repeat center top / cover;

	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	opacity: 0.1;
	// 	z-index: -1;	  
	// }
}

// .wpo-welcome-gallery {
// 	@include page-title-styles;
// 	background: url(../../images/page-title-2.jpg) no-repeat center top / cover;
// }