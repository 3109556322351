/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=DM+Sans:wght@400;500;700&display=swap');


// fonts
$base-font-size: 15px;
$base-font: 'DM Sans', sans-serif;

$heading-font: 'Calistoga';

// primary pallete: Blue primary action ,buttons, text links. Neutral Blue for body text and headings. White for page backgrouds
$primary-color: #103C75; // #16467c;
$neutral-primary-color: #103A49 ; //or 052639// for body text and headings
$color-text: #333333;
$secondary-color: #215F69; // or #1794A0
$secondary-color-2: #368F8B;

$accent-color: #fcb900; //#E7CA71; //
$accent-color-2: #e2bbb2;;
$neutral-color-1:#fff;
$neutral-color-2:#F4F6F3;
$neutral-color-3:#F3DFC1;
$neutral-color-4: #d9dcd6;


$body-bg-color: $neutral-color-1;
$section-bg-color:$neutral-color-2;
$text-color: $neutral-primary-color; 
$text-color-s2: $neutral-primary-color; 
$text-light-color:#57737A ;
$heading-color: $neutral-primary-color; 
$border-color: $neutral-primary-color;
$border-color-s2: $secondary-color-2;// #F2D1BF;
$border-color-s4: $neutral-color-2;
$red-color: #DC143C; //for error
$green-color: #14904D;
$white:#F4F6F3;
$black: #000;